@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');


h1 {
  font-size:10em;
  line-height:1em;

}
h3 {
  font-size:6em;
  line-height:1em;
}

/* SCROLLBAR FIXES*/

::-webkit-scrollbar {
display:none;
-ms-overflow-style:none;
scrollbar-width:none;
}
::-webkit-input-placeholder { /* WebKit browsers */
padding: 5px 0 0 5px;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
padding: 5px 0 0 5px;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
padding: 5px 0 0 5px;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
padding: 5px 0 0 5px;
}
textarea:focus, input:focus{
outline: none;
}


/* LIGHTBOX  */
#lightbox {
display: block;
position: absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
z-index: 7777;
animation: fadeBackground 1s;
animation-fill-mode: forwards;
}

#lightboxWindow {

max-width:100%;
max-height:80%;
display: flex;
flex-direction: column;
justify-content: space-between;
}
#lightboxWindow img {
object-fit:contain;
width:100%;
min-width:0;
min-height:0;
}

.lazy-load-image-background.lazy-load-image-loaded {
max-width:100%;
max-height: 90vh;
width:unset !important;
height:unset !important;
position:relative !important;
top:unset!important;
left:unset!important;
display:inline !important ;
}
.lazy-load-image-background {
width:50px;
height:50px;
top: 50%;
left: 50%;
}


.player2 {
font-family: 'Press Start 2P', cursive;
}





/* CONSOLE */
.code-yellow {
color:#c37a0e !important;

}
.code-green {
color:#409844 !important;
}
ul.welcome li:before {
content:'>';
color:#9c5dd6!important;
padding-right:15px;

}
.welcome li {
display:block;
position:relative;
overflow:hidden;
white-space:nowrap;
left:0;
opacity:0%;
animation: fadeBackground .25s ease 1s forwards,
typing 5s steps(100, end);
}

.welcome li:nth-child(1) {animation-delay:0s !important;}
.welcome li:nth-child(2) {animation-delay:5s !important;}
.welcome li:nth-child(3) {animation-delay:10s !important;}
.welcome li:nth-child(4) {animation-delay:15s !important;}
.welcome li:nth-child(5) {animation-delay:20s !important;}
.welcome li:nth-child(6) {animation-delay:30s !important;}



/* BOOTMENU */


#bootmenu p:nth-child(1) {animation-delay:0s !important;}
#bootmenu p:nth-child(2) {animation-delay:.25s !important;}
#bootmenu p:nth-child(3) {animation-delay:.5s !important;}
#bootmenu p:nth-child(4) {animation-delay:.75s !important;}
#bootmenu p:nth-child(5) {animation-delay:1s !important;}
#bootmenu p:nth-child(6) {animation-delay:1.25s !important;}
#bootmenu p:nth-child(7) {animation-delay:1.5s !important;}
#bootmenu p:nth-child(8) {animation-delay:1.75s !important;}
#bootmenu p:nth-child(9) {animation-delay:1.8s !important;}
#bootmenu p:nth-child(10) {animation-delay:1.9s !important;}
#bootmenu p:nth-child(11) {animation-delay:2s !important;}
#bootmenu p:nth-child(12) {animation-delay:2.2s !important;}


#bootmenu p {
animation: fadeBackground .25s ease 5s forwards,
typing 2s steps(12, end);
overflow:hidden;

white-space:nowrap;
left:0;
opacity:0%;
}
.bootline {
animation: trimTrail 5s;
height:25px;
text-align:left;
background-color:white;
display:block;
position:relative;
animation-timing-function: steps(3, end);

}
.bootlineholder {
width:100%;
border:1px solid #ffffff;
}

#bootmenu .bootload {
display:block;
position:relative;
overflow:hidden;
white-space:nowrap;
left:0;
opacity:0%;
animation:
fadeBackground .25s ease 1s forwards,
typing 5s steps(100, end);
}




/* NAVIGATION */



/*aniscroll*/
.aniScrollContainer {
position:absolute;
top:-50px;
left:10px;
height:auto;
width:100px;

}
.aniScrollContainer img{
position:absolute;
}

.navLinks {
z-index: 1000;
position:absolute;
/* top:75px; */
}

.navLinks img {
max-width: 75px;
right: 0px;
left: 5px;
height: auto;
}
.navItem canvas {
margin:0 auto;
max-width: 75px;
max-height:75px;
right: 0px;
left: 5px;
height: auto;
}
.dock {
border-top-right-radius:5px;
border-bottom-right-radius:5px;
padding:0px;
width:100px;
}

.blue {
  color:  #6fb1d2;
}

#main {
align-items:flex-start;
align-content:flex-start;

}
#console, #work, #about, #dialup {
align-self:stretch;
}
#intro h1::after{
  content: " | ";
  opacity:0;
  font-family:"Raydis";
  -webkit-text-fill-color: #6fb1d2;
  color:  #6fb1d2;
  margin:0; padding:0;
  display: inline-block;
  animation: cursor-blink 1s steps(2);
  animation-iteration-count: 15;
}
#intro h3::after {
  content: " | ";
  opacity:0;

  display: inline-block;
  animation: cursor-blink 1s steps(2);
  animation-delay:3s;
  animation-iteration-count: 30;
}

.placeholder {
min-height: 100vh;
}

li::marker {
color:#cccccc;
}

/* TAB AND PROJECT INFO */
.folder_tab {
position: absolute;
display: block;
z-index: 9;
top: -40px;
left: -0px;
height: 25px;
min-width: 100px;
max-width: 250px;
border-bottom: 0px !important;
}

.absolute.text-left.px-5.py-10 {
bottom: -74px;
right: 0px;
color:#368a67;
}

/* PROJECT CONTAINERS */
.projectContainer a {
position: relative;
}
.project-title {
margin-top:-25px;
padding-top:53px;
padding-bottom:33px;
height:max-content;

}
.imagerow {

z-index:2;
}

#lightbox .stacked {
box-shadow:5px 5px 5px  #00000050 !important;
}

.stacked {
  z-index:777;
}
.stacked:focus-within, .stacked:focus, .stacked:active {
/* border: 2px solid red; */
z-index:888;
box-shadow:5px 5px 5px  #00000050 !important;

}
/* PROJECT IMAGE GROUP */
.projectContainer:first-of-type {
margin-top:35px !important;
}

.gradient {
background-image: linear-gradient(
  to bottom,
  #ffdede 0%,
  #6fb1d2 30%,
  #6ab6ec 50%,
  #000000 80%,
  #000000 100%
  );

}
.gradient_text{
background-image: linear-gradient(
  to bottom,
  transparent 0%,
  #6fb1d2 75%,
  transparent  100%
  );
}

.gradient_text_green{
background-image: linear-gradient(
  to bottom,
  transparent 0%,
  #4ade80 75%,
  transparent  100%
  );
}




.gradientshift {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: 100% 4px;
  animation: gradientshift 155s linear infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.h-minustop {
  height:calc(100% - 17px);
}


.imagesPre {
top:0px;
z-index:9;
border-top-right-radius:5px;
border-top-left-radius:5px;
height:auto;
font-size:10px !important;
letter-spacing:2px;
color:black;
padding:11px;
margin:0px;
}
img.image {
display: block;
width: 100%;
height: inherit;
min-height: 200px;
margin: 0 auto;
vertical-align: middle;
background-clip: content-box;
overflow: hidden;
object-fit: cover;
object-position: center;
background-color: #fff;
display: none;
}





.fadeIn {
animation: fadeOut 1s;
animation-fill-mode: backwards;
}

.fadeOut {
animation: fadeOut 1s;
animation-fill-mode: forwards;
}
#lightbox.fadeOut {
animation: fadeOut 1s;
animation-fill-mode: forwards;
}



/* TAILWIND ADDITIONS */


.red-hat {
font-family: 'Red Hat Mono', monospace;
}
.glimmer {
animation: glimmer 2s infinite;
}


.h-200px {
height: 200px;
position: relative;
overflow: hidden;
max-width: 200px;
}
.white-bg {
background-color:white;
}
.green-bg {
background-color:#57c98d;
}
.green-border {
border-color:#368a67;

}
.text-green {
color:#57c98d;
}
.text-darkgreen {
color:#368a67;
}

.floating {
transform: translateY(0px);
animation: float2 5s ease-in-out infinite;
}
/* ANIMATIONS */
@keyframes gradientshift  {
  0% {
    background-position:0% 0%;
  }
  50% {
    background-position:0% 100%;
  }
  100% {
    background-position:0% 0%;
  }
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity:.8
  }
  100% {
    opacity:1;
  }
}

@keyframes typing {
from { width: 0 }
to {width: 100%;}
}

@keyframes glimmer {
0% {
opacity:0%;
}
1% {
opacity:100%;
}
50% {
opacity:100%;
}
89% {
opacity:100%;
}
90% {
opacity:0%;
}
100% {
opacity:0%;
}
}
@keyframes trimTrail {
0% {
width:0;
}
10% {
width:12%;
}
12% {
width:25%;
}
15% {
width:25%;
}
20% {
width:25%;
}
30% {
width: 25%;
}
40% {
width:25%;
}
60% {
width:65%;
}
90% {
width:75%;
}
100% {
width:100%;
}
}
@keyframes fadeBackground {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
@keyframes fadeIn {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
@keyframes fadeOut {
0% {
opacity: 1;
}
100% {
opacity: 0;
}
}

@keyframes scroll-left {
0% {
-moz-transform: translateX(100%);
-webkit-transform: translateX(100%);
transform: translateX(100%);
}

100% {
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);
}
}


/* FOOTER */
.marquee {
height: 25px;
width: 100%;
overflow: hidden;
position: relative;
}

footer {
position: absolute;
bottom:0px;
width: 100%;
height: 35px;
background-color: transparent;
position: fixed;
overflow-x: hidden;
width: 100%;
z-index: 999;
background-color:orange;
cursor:pointer;
}

.marquee p {
position: absolute;
width: 100%;
height: 100%;
margin: 0;
line-height: 25px;
text-align: center;
-moz-transform: translateX(100%);
-webkit-transform: translateX(100%);
transform: translateX(100%);
-moz-animation: scroll-left 2s linear infinite;
-webkit-animation: scroll-left 2s linear infinite;
animation: scroll-left 20s linear infinite;

text-transform: uppercase;
font-size: 11px;
letter-spacing: 3px;
font-weight: bold;
}


@font-face {
  font-family: "Raydis";
  src: local("Raydis"),
  url("./fonts/RAYDIS.ttf") format("truetype");
  font-weight:bold;
}
.raydis {
  font-family: "Raydis";

}

@media (max-width: 640px) {
.navLinks {
top:unset !important;
}
h1 {
  font-size:4em !important;
  line-height:1em;

}
h3 {
  font-size:3em !important;
  line-height:1em;
}

}
